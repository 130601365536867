<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="departments"
      class="mt-1"
      item-key="id"
      show-select
      single-select
      hide-default-footer
    >
      <template v-slot:item.regionMergerName="{ item }">
        {{ item.regionMergerName.replaceAll(',', '-') }}
      </template>

      <template v-slot:item.depLevel="{ item }">
        {{ item.depLevel | getInstitutionLevelName }}
      </template>

    </v-data-table>
    <v-pagination
      v-model="queryParams.params.page"
      :length="queryParams.params.pageCount"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import bus from '@/services/bus-event';

  const INSTITUTIONLEVEL = {
    101: '省级中心医院',
    201: '市级中心医院',
    301: '县级中心医院',
    401: '乡镇卫生院',
    501: '村级卫生室'
  }

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        departments: 'department/departments'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.department.name'),
            align: 'start',
            sortable: false,
            value: 'showName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.belongToArea'),
            align: 'start',
            sortable: false,
            value: 'regionMergerName',
          },{
            text: this.$vuetify.lang.t('$vuetify.department.level'),
            align: 'start',
            sortable: false,
            value: 'depLevel',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.departmentQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      },

      selected: {
        handler: function(newValue) {
          bus.emit('institution-change', newValue)
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      ...mapActions ({
        departmentQuery: 'department/query'
      }),

      initialize() {
        let params = Object.assign({}, this.queryParams.params)
        params.page--
        this.departmentQuery({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleDistribute  () {
        this.dialogEquipmentList = true
      },

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.departmentQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      }
    },

    filters: {
      getInstitutionLevelName (key) {
        return INSTITUTIONLEVEL[key] || key
      }
    }
  }
</script>
